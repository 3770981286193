<template lang="pug">
lot-card(
  v-if="application"
  show-calc-form
  type="app"
  :lot="application"
)
  template(#title)
    lot-name(:lot="application")
  template(#template-info)
    template-info(:templates="application?.app?.templates || []")
  template(#status)
    app-status-selector(
      :lot="application"
      :disabled="roleReadOnly || application?.app?.isArchived"
      @show-create-contract="$emit('showCreateContract', $event)"
      @extract-from-archive="extractFromArchive"
    )
  template(#responsible-person)
    manager-selector(
      v-model="responsiblePerson"
      label="Ответственный"
      :show-set-me-link="!isTmManager"
      :persistent="false"
      :teleported="false"
      :disabled="roleReadOnly || application?.app?.isArchived"
      @set-manager="selectResponsible"
    )
  template(#dates)
    application-item-dates(:application="application")
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useAppModifier } from "~/components/pages/applications/list/useAppModifier";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import AppStatusSelector from "@/components/selector/tenderStatuses/AppStatusSelector.vue";
import TemplateInfo from "@/components/mainStreamPage/lotItem/TemplateInfo.vue";
import LotCard from "@/components/cards/LotCard.vue";
import ManagerSelector from "@/components/selector/ManagerSelector.vue";
import LotName from "~/components/cards/modules/LotName.vue";
import ApplicationItemDates from "~/components/pages/applications/card/ApplicationItemDates.vue";

import type { PropType } from "vue";
import type { SimpleManagerI } from "@/stores/auth/UserInterface";
import type { ManagerI } from "@/stores/auth/UserInterface";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "ApplicationItemCommon",
  components: {
    LotName,
    LotCard,
    AppStatusSelector,
    ManagerSelector,
    TemplateInfo,
    ApplicationItemDates,
  },
  props: {
    application: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
  },
  emits: [
    'showCreateContract',
  ],
  setup(props) {

    const { roleReadOnly, isTmManager } = useUserAccess();
    const { updateAppResponsible, updateAppArchived } = useAppModifier();

    const responsiblePerson = ref<SimpleManagerI|null>(props.application?.app?.responsiblePerson || null)

    watch(() => props.application?.app?.responsiblePerson, (newValue) => {
      responsiblePerson.value = newValue || null
    })

    function selectResponsible(p: { newValue: ManagerI | null, oldValue: ManagerI | null }) {
      updateAppResponsible(props.application, p.newValue, p.oldValue)
    }

    function extractFromArchive() {
      updateAppArchived(props.application)
    }

    return {
      isTmManager,
      roleReadOnly,
      responsiblePerson,
      selectResponsible,
      extractFromArchive,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
