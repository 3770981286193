<template lang="pug">
#main_application
  .page-title__wrapper(:class="!enableTemplates && '_margin'")
    h1.main-page-title {{ h1Title }}
    ui-button(
      v-if="!enableTemplates"
      :icon-left="UiIconNames.Icon_Search"
      @click="startNewSearch"
    ) Новый поиск
  .templates-search__wrapper(v-if="enableTemplates")
    template-selector(
      v-model="selectedTemplatesIds"
      @accept="searchApps"
      @clear:select="clearSelect"
    )
    ui-button(
      size="large"
      style="white-space: nowrap"
      :icon-left="UiIconNames.Icon_Search"
      @click="startNewSearch"
    ) Новый поиск
    icon-button(
      as-button
      button-type="tertiary"
      tooltip-title="Очистить страницу"
      :icon="UiIconNames.Icon_CircleClose"
      @click="clearSelect"
    )

  list-options(
    found-title="Найдено заявок"
    :options="info.options"
  )
  filter-panel(
    type="filters"
    v-model="preFiltrationForm"
    :columns="columns"
    :has-active-filters="hasActiveFilters"
    :is-downloading="isAppsDownloading"
    @apply="fetchApps"
    @reset="onResetClick"
    @download="downloadExcel"
  )
  applications-list(
    :data="data"
    :info="info"
    @reload="updateTemplateOutput"
    @load-next-part="updateTemplateOutput"
  )
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { getDefaultPreFiltrationFormApp } from "@/utils/getters/defaultFilters";
import { useAppList } from "@/components/pages/applications/list/useAppList";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { prepareAppData } from "@/utils/api/searchRequests";
import { downloadFromBackend } from "@/utils/download/fileDownloader";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { useFiltersPanel } from "@/use/filters/useFiltersPanel";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";
import { Tabs } from "@/router/tabs";

import TemplateSelector from "@/components/selector/templateSelector/TemplateSelector.vue";
import ApplicationsList from "@/components/pages/applications/list/ApplicationsList.vue";
import ListOptions from "@/components/mainStreamPage/metaInfo/ListOptions.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import FilterPanel from "@/components/ui/filters/FilterPanel.vue";

import useAuthStore from "@/stores/auth/useAuthStore";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "AppPanel",
  components: {
    FilterPanel,
    IconButton,
    UiButton,
    ApplicationsList,
    TemplateSelector,
    ListOptions,
  },
  setup() {

    const route = useRoute();
    const router = useRouter();

    const authStore = useAuthStore();

    const h1Title = computed(() => route.meta.title);
    const isAppsDownloading = ref(false);

    const {
      data,
      info,
      pageStatuses,
      preFiltrationForm,
      selectedTemplatesIds,
      setTab,
      fetchApps,
      updateTemplateOutput,
      cancelAppsRequests,
    } = useAppList();

    const {
      isTmManager,
      enableTemplates,
    } = useUserAccess();

    const {
      managerCompanyTitle,
    } = useSuperAccount();

    const { storage } = useServiceStorage();

    const defaultFiltrationForm = getDefaultPreFiltrationFormApp()
    const {
      appsColumns: columns,
      hasActiveFilters,
      onResetClick,
    } = useFiltersPanel(preFiltrationForm, defaultFiltrationForm, fetchApps);

    function startNewSearch() {
      storage.openNewSearch = true;
      router.push({ name: Tabs.Search });
    }

    function searchApps(payload: { templates: number[], groupId: number|null, templateId: number|null }) {
      selectedTemplatesIds.value = payload.templates || []
      preFiltrationForm.value = getDefaultPreFiltrationFormApp();
      fetchApps()
    }

    function downloadExcel() {
      const route = "search/apps/download";
      const data = prepareAppData(selectedTemplatesIds.value, pageStatuses.value, preFiltrationForm.value)
      const fileName = `Заявки компании ${ isTmManager.value ? managerCompanyTitle.value : (authStore.company?.title || authStore.me.fio) }`

      downloadFromBackend(isAppsDownloading, route, data, fileName)
    }

    const paramsMode = computed(() => route.name);

    watch(paramsMode, () => {

      if ([Tabs.Applications.Main, Tabs.Applications.Calculation, Tabs.Applications.Participation, Tabs.Applications.Bargaining].includes(route.name as string || '')) {
        setTab(route.name);
      } else {
        return;
      }

      if (route.query.govRuId) {
        preFiltrationForm.value.govRuIds = [route.query.govRuId]
        router.replace({ query: {} });
      }

      fetchApps()
    }, { immediate: true });

    onBeforeRouteLeave((to: any, from: any, next: any) => {
      cancelAppsRequests()
      next();
    })

    function clearSelect() {
      cancelAppsRequests()

      selectedTemplatesIds.value = [];
      preFiltrationForm.value = getDefaultPreFiltrationFormApp();
      fetchApps()
    }

    return {
      h1Title,
      data,
      info,
      columns,
      hasActiveFilters,
      enableTemplates,
      preFiltrationForm,
      defaultFiltrationForm,
      isAppsDownloading,
      selectedTemplatesIds,
      clearSelect,
      startNewSearch,
      searchApps,
      fetchApps,
      updateTemplateOutput,
      onResetClick,
      downloadExcel,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import "@/assets/styles/pages/main";

#main_application {
  @include page-view;
}
</style>
