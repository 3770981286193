<template lang="pug">
list-wrapper(
  :info="info"
  :count="data.length"
  no-data-text="Заявки не найдены"
  result-text="Кол-во найденных заявок"
  @reload="$emit('reload')"
  @load-next-part="$emit('loadNextPart')"
)
  application-item(
    v-for="item of data"
    :key="item.lotId"
    :application="item"
    @show-create-contract="openCreateContractDialog"
  )

create-contract-dialog(
  v-model:show="showCreateContract"
  :cost="createContractPayload.cost"
  :responsible="createContractPayload.responsiblePerson"
  :lot-id="createContractPayload.lotId"
  @on:hide="onHideDialog"
)
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, ref } from "vue";
import { getEntityListInfoSelective } from "@/utils/getters/defaultRequestInfo";

import ApplicationItem from "@/components/pages/applications/list/ApplicationItem.vue";
import ListWrapper from "@/components/mainStreamPage/metaInfo/ListWrapper.vue";

import type { PropType } from "vue";
import type { MainStreamListInfo } from "@/utils/getters/defaultRequestInfo";
import type { AppItemInterface } from "@/stores/search/LotItemInterface";
import type { CostItemI } from "@/stores/manuals/ManualsInterface";
import type { ManagerI } from "@/stores/auth/UserInterface";

export default defineComponent({
  name: "ApplicationsList",
  components: {
    ListWrapper,
    ApplicationItem,
    CreateContractDialog: defineAsyncComponent(
      () => import("@/components/mainStreamPage/lotItem/dialogs/CreateContractDialog.vue")
    ),
  },
  emits: [
    'reload',
    'loadNextPart',
  ],
  props: {
    data: {
      type: Array as PropType<AppItemInterface[]>,
      default: () => [],
    },
    info: {
      type: Object as PropType<MainStreamListInfo>,
      default: getEntityListInfoSelective,
    },
  },
  setup() {

    const showCreateContract = ref(false);
    const createContractPayload: any = ref(getDefaultPayload());

    function getDefaultPayload() {
      return {
        responsiblePerson: null,
        cost: null,
        lotId: null,
      }
    }

    function onHideDialog() {
      createContractPayload.value = getDefaultPayload();
    }

    function openCreateContractDialog(payload: { responsiblePerson: ManagerI, cost: CostItemI, lotId: number }) {
      createContractPayload.value = payload;
      showCreateContract.value = true;
    }

    return {
      showCreateContract,
      createContractPayload,
      onHideDialog,
      openCreateContractDialog,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
