import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { getEntityListInfoSelective } from "@/utils/getters/defaultRequestInfo";
import { cancelRequest, commonSupplemental, getEntitiesList, prepareAppData } from "@/utils/api/searchRequests";
import { checkTagFiltersOnActuality } from "~/utils/searchForm/searchFormWorker";
import useSearchStore from "@/stores/search/useSearchStore";
import useApplicationsStore from "@/stores/apps/useApplicationsStore";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";
import type { AppItemInterface } from "@/stores/search/LotItemInterface";
import type { MainStreamListInfo } from "@/utils/getters/defaultRequestInfo";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

export function useAppList() {

  const data = ref<AppItemInterface[]>([]);
  const info = ref<MainStreamListInfo>(getEntityListInfoSelective());

  const searchStore = useSearchStore();
  const interfaceStore = useInterfaceSettingsStore();

  const { showWarning } = useNotificationsStore();

  const applicationStore = useApplicationsStore();
  const { setTab, updatePages } = applicationStore;
  const {
    pageStatuses,
    preFiltrationForm,
  } = storeToRefs(applicationStore);

  const {
    needReload,
  } = storeToRefs(searchStore);

  const {
    selectedTemplatesIds,
  } = storeToRefs(interfaceStore.functions.saveFiltersOnRedirect ? searchStore : applicationStore);

  function fetchAppsList(fetchData: any) {
    getEntitiesList(info, fetchData, "search/apps")
      .then((newData) => {
        // @ts-ignore
        data.value = data.value.concat(newData);
      })
      .catch(() => {})
  }

  function fetchAppsSupplemental(fetchData: any) {
    commonSupplemental(info, fetchData, "search/apps/supplemental")
  }

  function reset() {
    data.value = [];
    info.value = getEntityListInfoSelective();
  }

  // inner use only
  function fetchEntity(preFiltrationForm: PreFiltrationFormI, withSupplemental: boolean) {
    const fetchData = prepareAppData(selectedTemplatesIds.value, pageStatuses.value, preFiltrationForm)
    fetchAppsList(fetchData)
    if (withSupplemental) fetchAppsSupplemental(fetchData)
  }

  // подгрузка данных
  function updateTemplateOutput() {
    fetchEntity(preFiltrationForm.value, false);
  }

  // поиск заявок
  function fetchApps() {
    updatePages()
    cancelAppsRequests()
    reset()
    fetchEntity(preFiltrationForm.value, true)
  }

  function cancelAppsRequests() {
    cancelRequest(info)
  }

  watch(needReload, (value) => {
    if (value) {
      const value = checkTagFiltersOnActuality(preFiltrationForm.value.tagIds, searchStore.tags)
      if (value) {
        preFiltrationForm.value.tagIds = value
        fetchApps()
        showWarning('Список тендеров был перезапрошен, так как фильтр по тегам содержал неактуальные значения', 4000, 'Внимание')
      }
      needReload.value = false;
    }
  })

  return {
    data,
    info,
    pageStatuses,
    preFiltrationForm,
    selectedTemplatesIds,
    setTab,
    fetchApps,
    updateTemplateOutput,
    cancelAppsRequests,
  }
}
